import React, { useEffect, useState, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import 'chart.js/auto';
import styles from '../css/ChartComponent.module.css';
import { loadCharts, updateChartData } from '../charts';
import Statistics from './Statistics';
import Menu from './Menu';
import { calculateStatistics, processGrades } from '../chartutils';

const QuestionVisibilityControls = ({ questions, hiddenQuestions, onToggleQuestion, onBulkAction }) => {
    return (
        <div className={styles.visibilityControls}>
            <div className={styles.controlsHeader}>
                <h4 className={styles.controlsTitle}>Hide/Show Questions</h4>
                <div className={styles.controlsButtons}>
                    <button 
                        className={styles.controlButton}
                        onClick={() => onBulkAction('HIDE_ALL')}
                    >
                        Hide All
                    </button>
                    <button 
                        className={styles.controlButton}
                        onClick={() => onBulkAction('SHOW_ALL')}
                    >
                        Show All
                    </button>
                    <button 
                        className={styles.controlButton}
                        onClick={() => onBulkAction('RESET')}
                    >
                        Reset to Default
                    </button>
                </div>
            </div>
            <div className={styles.checkboxGrid}>
                {Object.keys(questions).sort().map(questionId => (
                    <label key={questionId} className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={!hiddenQuestions.includes(questionId)}
                            onChange={() => onToggleQuestion(questionId)}
                        />
                        {questionId}
                    </label>
                ))}
            </div>
        </div>
    );
};

const ChartComponent = ({ courseCode, assignmentId }) => {
    const [chartData, setChartData] = useState(null);
    const [assignmentName, setAssignmentName] = useState('');
    const [view, setView] = useState('metrics');
    const [averageGrade, setAverageGrade] = useState(null);
    const [medianGrade, setMedianGrade] = useState(null);
    const [highestGrade, setHighestGrade] = useState(null);
    const [lowestGrade, setLowestGrade] = useState(null);
    const [stdDevGrade, setStdDevGrade] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [demoMode, setDemoMode] = useState(false);
    const [detailedPartialMarks, setDetailedPartialMarks] = useState(false);
    const [assignmentNotFound, setAssignmentNotFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [updateProgress, setUpdateProgress] = useState(0);
    const [hiddenQuestions, setHiddenQuestions] = useState([]);

    useEffect(() => {
        if (assignmentId) {
            const savedPreferences = localStorage.getItem(`hiddenQuestions_${assignmentId}`);
            if (savedPreferences) {
                setHiddenQuestions(JSON.parse(savedPreferences));
            } else {
                setHiddenQuestions([]); // Reset if no saved preferences
            }
        }
    }, [assignmentId]);

    const handleToggleQuestion = (questionId) => {
        setHiddenQuestions(prev => {
            const newHidden = prev.includes(questionId)
                ? prev.filter(id => id !== questionId)
                : [...prev, questionId];
            localStorage.setItem(`hiddenQuestions_${assignmentId}`, JSON.stringify(newHidden));
            return newHidden;
        });
    };

    const handleBulkAction = (action) => {
        let newHidden = [];
        
        switch(action) {
            case 'HIDE_ALL':
                newHidden = Object.keys(chartData.rawQuestionData);
                break;
            case 'SHOW_ALL':
                newHidden = [];
                break;
            case 'RESET':
                newHidden = [];
                localStorage.removeItem(`hiddenQuestions_${assignmentId}`);
                break;
            default:
                return;
        }
        
        setHiddenQuestions(newHidden);
        if (action !== 'RESET') {
            localStorage.setItem(`hiddenQuestions_${assignmentId}`, JSON.stringify(newHidden));
        }
    };
    const interpolateColor = (factor) => {
        // For factor = 1.0, should be green (#BAEFB9)
        // For factor = 0.5, should be yellow (#FFD700)
        // For factor = 0.0, should be red (#FD3C08)
        if (factor >= 0.5) {
            // From yellow to green (0.5 -> 1.0)
            const adjustedFactor = (factor - 0.5) * 2; // Convert 0.5-1.0 to 0-1
            const r = Math.round(255 - (71 * adjustedFactor));   // 255 -> 186 (BA in hex)
            const g = Math.round(215 + (24 * adjustedFactor));   // 215 -> 239 (EF in hex)
            const b = Math.round(0 + (185 * adjustedFactor));    // 0 -> 185 (B9 in hex)
            return `rgb(${r}, ${g}, ${b})`;
        } else {
            // From red to yellow (0.0 -> 0.5)
            const adjustedFactor = factor * 2; // Convert 0-0.5 to 0-1
            const r = 255;  // Stays at 255
            const g = Math.round(60 + (155 * adjustedFactor));   // 60 -> 215
            const b = Math.round(8 + (-8 * adjustedFactor));     // 8 -> 0
            return `rgb(${r}, ${g}, ${b})`;
        }
    };

    const simulateProgress = () => {
        setUpdateProgress(0);
        const interval = setInterval(() => {
            setUpdateProgress((oldProgress) => {
                if (oldProgress >= 90) {
                    clearInterval(interval);
                    return 90;
                }
                return oldProgress + 10;
            });
        }, 500);
        return interval;
    };

    useEffect(() => {
        const storedDemoMode = localStorage.getItem('demoMode') === 'true';
        setDemoMode(storedDemoMode);

        const fetchChartData = async () => {
            if (courseCode && assignmentId) {
                try {
                    const resp = await loadCharts(courseCode, assignmentId, storedDemoMode);
                    
                    setAssignmentName(resp.assignment_name);
                    setChartData({
                        rawExerciseData: resp.results_by_exercise,
                        rawQuestionData: resp.results_by_question,
                        rawGrades: resp.grades
                    });
    
                    setLastUpdated(resp.last_updated);
                    
                    calculateStatistics(
                        resp.grades,
                        setAverageGrade,
                        setMedianGrade,
                        setHighestGrade,
                        setLowestGrade,
                        setStdDevGrade
                    );
                    
                    setAssignmentNotFound(false);
                    setErrorMessage(null);
                } catch (error) {
                    console.error('Error loading chart data:', error);
                    setAssignmentNotFound(true);
                    setErrorMessage(error.message || 'An unexpected error occurred');
                }
            }
        }
        fetchChartData();
    }, [courseCode, assignmentId, demoMode]);

    const processDataWithVisibility = (rawData, detailedPartialMarks, hiddenQuestions) => {
        if (!rawData || Object.keys(rawData).length === 0) {
            return { labels: [], datasets: [], totalStudents: 0 };
        }

        // Filter out hidden questions
        const visibleQuestions = Object.keys(rawData)
            .filter(key => !hiddenQuestions.includes(key))
            .sort();

        const datasets = [
            {
                label: '# Fully correct',
                data: visibleQuestions.map(label => rawData[label]['# Fully correct'] || 0),
                backgroundColor: '#BAEFB9'
            },
            {
                label: '# Partially correct',
                data: visibleQuestions.map(label => rawData[label]['# Partially correct'] || 0),
                backgroundColor: '#FFB715'
            },
            {
                label: '# Fully incorrect',
                data: visibleQuestions.map(label => rawData[label]['# Fully incorrect'] || 0),
                backgroundColor: '#FD3C08'
            },
            {
                label: '# Not checked',
                data: visibleQuestions.map(label => rawData[label]['# Not checked'] || 0),
                backgroundColor: '#5D6CC9'
            },
            {
                label: '# Not attempted',
                data: visibleQuestions.map(label => rawData[label]['# Not attempted'] || 0),
                backgroundColor: '#871751'
            }
        ];

        if (detailedPartialMarks) {
            const partialMarksCount = new Map();
            const partialDatasets = [];
            const allPartialMarks = new Set();
            
            // First pass: collect all partial marks and count frequencies
            visibleQuestions.forEach(key => {
                const partialMarks = rawData[key]['Partial marks'] || {};
                Object.entries(partialMarks).forEach(([mark, count]) => {
                    if (count > 0) {
                        const numMark = Number(mark);
                        allPartialMarks.add(numMark);
                        partialMarksCount.set(numMark, (partialMarksCount.get(numMark) || 0) + count);
                    }
                });
            });

            // Get top 10 most frequent marks for legend
            const topPartialMarks = Array.from(partialMarksCount.entries())
                .sort((a, b) => b[1] - a[1]) // Sort by count descending
                .slice(0, 10) // Take top 10
                .map(([mark]) => mark);

            // Create datasets for ALL partial marks
            Array.from(allPartialMarks).forEach(numMark => {
                partialDatasets.push({
                    label: `Partial mark ${numMark}`,
                    data: visibleQuestions.map(() => 0),
                    backgroundColor: interpolateColor(numMark),
                    mark: numMark,
                    // Add a flag to hide in legend if not in top 10
                    showInLegend: topPartialMarks.includes(numMark)
                });
            });

            // Fill in the data
            visibleQuestions.forEach((key, index) => {
                const partialMarks = rawData[key]['Partial marks'] || {};
                Object.entries(partialMarks).forEach(([mark, count]) => {
                    const numMark = Number(mark);
                    const dataset = partialDatasets.find(ds => ds.mark === numMark);
                    if (dataset) {
                        dataset.data[index] = count;
                    }
                });
            });
            
            // Sort partialDatasets by mark value
            partialDatasets.sort((a, b) => b.mark - a.mark);
            
            datasets.splice(1, 1);
            datasets.splice(1, 0, ...partialDatasets);
        }

        return {
            labels: visibleQuestions,
            datasets,
            totalStudents: Math.max(...visibleQuestions.map(q => 
                Object.values(rawData[q]).reduce((sum, val) => 
                    typeof val === 'number' ? sum + val : sum, 0)
            ))
        };
    };

    const currentData = useMemo(() => {
        if (!chartData) return null;
        return view === 'metrics' 
            ? processGrades(chartData.rawGrades) 
            : processDataWithVisibility(
                view === 'exercise' ? chartData.rawExerciseData : chartData.rawQuestionData,
                detailedPartialMarks,
                hiddenQuestions
            );
    }, [chartData, view, detailedPartialMarks, hiddenQuestions]); // Include hiddenQuestions in dependencies


    const chartOptions = useMemo(() => {
        if (!currentData) return {};
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'right',
                    display: true,
                    labels: {
                        font: { size: 16 },
                        generateLabels: (chart) => {
                            const datasets = chart.data.datasets;
                            return datasets
                                .map((dataset, i) => ({
                                    text: dataset.label.startsWith('Partial mark') 
                                        ? `Partial mark ${dataset.mark.toFixed(4)}`
                                        : dataset.label,
                                    fillStyle: dataset.backgroundColor,
                                    hidden: false,
                                    lineCap: undefined,
                                    lineDash: undefined,
                                    lineDashOffset: undefined,
                                    lineJoin: undefined,
                                    lineWidth: undefined,
                                    strokeStyle: undefined,
                                    pointStyle: undefined,
                                    datasetIndex: i
                                }))
                                // Filter out partial marks not in top 10 from legend only
                                .filter(label => {
                                    const dataset = datasets[label.datasetIndex];
                                    return !dataset.label.startsWith('Partial mark') || 
                                           dataset.showInLegend;
                                });
                        }
                    },
                },
                title: {
                    display: true,
                    text: view === 'metrics' ? `${assignmentName} grade distribution` : `${assignmentName} results by ${view}`,
                    font: { size: 24 }
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const datasetLabel = context.dataset.label || '';
                            const value = context.raw;
                            const mark = Number(context.dataset.mark);
                            if (datasetLabel.startsWith('Partial mark')) {
                                return `${datasetLabel}: ${mark.toFixed(2)} (${value} submissions)`;
                            }
                            return `${datasetLabel}: ${value}`;
                        }
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        text: view === 'metrics' ? 'Grade' : '',
                        font: { size: 18 }
                    },
                    ticks: { font: { size: 14 } }
                },
                y: {
                    stacked: true,
                    max: currentData.totalStudents,
                    title: {
                        display: true,
                        text: 'Number of submissions',
                        font: { size: 18 }
                    },
                    ticks: {
                        font: { size: 14 },
                        beginAtZero: true,
                        stepSize: Math.ceil(currentData.totalStudents / 5)
                    }
                }
            }
        };
    }, [currentData, view, assignmentName]);

    const downloadChart = () => {
        const chart = document.getElementsByTagName('canvas')[0];
        const link = document.createElement('a');
        const whiteBackground = document.createElement('canvas');
        const context = whiteBackground.getContext('2d');
        
        whiteBackground.width = chart.width;
        whiteBackground.height = chart.height;
        
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, whiteBackground.width, whiteBackground.height);
        
        context.drawImage(chart, 0, 0);
    
        link.download = view === 'metrics' ? `${assignmentName} grade distribution.png` : `${assignmentName} results by ${view}.png`;
        link.href = whiteBackground.toDataURL('image/png');
        link.click();
    };

    const updateChartDataHandler = async () => {
        setIsLoading(true);
        const progressInterval = simulateProgress();
        try {
            const resp = await updateChartData(courseCode, assignmentId);
            setAssignmentName(resp.assignment_name);
            setChartData({
                rawExerciseData: resp.results_by_exercise,
                rawQuestionData: resp.results_by_question,
                rawGrades: resp.grades
            });
            setLastUpdated(resp.last_updated);
            calculateStatistics(resp.grades, setAverageGrade, setMedianGrade, setHighestGrade, setLowestGrade, setStdDevGrade);
            setUpdateProgress(100);
        } catch (error) {
            console.error('Error updating chart data:', error);
            alert('Failed to update chart data. Please try again later.');
        } finally {
            clearInterval(progressInterval);
            setTimeout(() => {
                setIsLoading(false);
                setUpdateProgress(0);
            }, 500);
        }
    };

    const ProgressBar = ({ progress }) => {
        return (
            <div className={styles.progressBarContainer}>
                <div 
                    className={styles.progressBarFill} 
                    style={{ width: `${progress}%` }}
                />
                <div className={styles.progressBarText}>
                    {progress < 100 ? 'Updating...' : 'Update Complete!'}
                </div>
            </div>
        );
    };

    const handleExitDemo = () => {
        localStorage.removeItem('demoMode');
        navigate('/login');
    };

    if (!chartData) return <div>Loading...</div>;

    return (
        <div>
            <Menu view={view} setView={setView} />
            <div className={styles.chartContent}>
                {errorMessage && (
                    <div className={styles.errorMessage}>
                        Error: {errorMessage}
                    </div>
                )}
                {view === 'metrics' && (
                    <Statistics 
                        chartData={chartData} 
                        averageGrade={averageGrade}
                        medianGrade={medianGrade}
                        highestGrade={highestGrade}
                        lowestGrade={lowestGrade}
                        stdDevGrade={stdDevGrade}
                    />
                )}
                {isLoading ? (
                    <div className={styles.loadingContainer}>
                        <ProgressBar progress={updateProgress} />
                    </div>
                ) : currentData && currentData.labels && currentData.labels.length > 0 ? (
                    <Bar
                        data={{
                            labels: currentData.labels,
                            datasets: currentData.datasets
                        }}
                        options={chartOptions}
                    />  
                ) : (
                    <div>No data available for the current view.</div>
                )}
                
                {/* Move QuestionVisibilityControls here, after the chart */}
                {view === 'question' && chartData?.rawQuestionData && (
                    <div className={styles.belowChartControls}>
                        <QuestionVisibilityControls
                            questions={chartData.rawQuestionData}
                            hiddenQuestions={hiddenQuestions}
                            onToggleQuestion={handleToggleQuestion}
                            onBulkAction={handleBulkAction}
                        />
                    </div>
                )}
    
                <div className={styles.buttonContainer}>
                    <button className={styles.downloadButton} onClick={downloadChart}>
                        Download Chart
                    </button>
                    {view !== 'metrics' && (
                        <div className={styles.toggleContainer}>
                            <label className={styles.toggleLabel}>Stacked</label>
                            <label className={styles.switch}>
                                <input 
                                    type="checkbox"
                                    checked={detailedPartialMarks}
                                    onChange={() => setDetailedPartialMarks(!detailedPartialMarks)}
                                />
                                <span className={`${styles.slider} ${styles.round}`}></span>
                            </label>
                            <label className={styles.toggleLabel}>Detailed</label>
                        </div>
                    )}
                    {demoMode ? (
                        <button className={styles.downloadButton} onClick={handleExitDemo}>
                            Exit Demo Mode
                        </button>
                    ) : (
                        <button className={styles.downloadButton} onClick={updateChartDataHandler} disabled={isLoading}>
                            {isLoading ? 'Updating...' : 'Update Chart'}
                        </button>
                    )}
                </div>
                {!demoMode && lastUpdated && (
                    <p className={styles.lastUpdated}>Last updated:<br />{new Date(lastUpdated).toLocaleString()}</p>
                )}
            </div>
        </div>
    );
};

export default ChartComponent;
