import { API_URL } from './config';

const fetchWithRetry = async (url, options, maxRetries = 3) => {
  for (let i = 0; i < maxRetries; i++) {
      try {
          const response = await fetch(url, options);
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          return await response.json();
      } catch (error) {
          if (i === maxRetries - 1) throw error;
          await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
      }
  }
};

export async function loadCharts(courseCode, assignmentId, demoMode = false, update = false) {
  try {
      if (demoMode) {
          return await fetchWithRetry(`${API_URL}/demo/`, {
              method: 'GET',
              credentials: 'include'
          });
      } else {
          const apiKey = localStorage.getItem('apiKey');
          if (!apiKey) {
              throw new Error('No API key found');
          }
          const headers = {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${apiKey}`
          };

          const url = update 
              ? `${API_URL}/courses/${encodeURIComponent(courseCode)}/assignments/${encodeURIComponent(assignmentId)}/update/?update=true`
              : `${API_URL}/courses/${encodeURIComponent(courseCode)}/assignments/${encodeURIComponent(assignmentId)}/`;

          return await fetchWithRetry(url, {
              method: 'GET',
              headers: headers,
              credentials: 'include'
          });
      }
  } catch (error) {
      console.error('Error in loadCharts:', error);
      throw new Error(`Failed to load charts: ${error.message}`);
  }
}

export async function updateChartData(courseCode, assignmentId) {
  const apiKey = localStorage.getItem('apiKey');
  if (!apiKey) {
      throw new Error('No API key found');
  }
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
  };

  try {
      return await fetchWithRetry(`${API_URL}/courses/${encodeURIComponent(courseCode)}/assignments/${encodeURIComponent(assignmentId)}/update/?update=true`, {
          method: 'GET',
          headers: headers,
          credentials: 'include'
      });
  } catch (error) {
      console.error('Error updating chart data:', error);
      throw new Error(`Failed to update chart data: ${error.message}`);
  }
}