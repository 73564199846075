export const calculateStatistics = (grades, setAverageGrade, setMedianGrade, setHighestGrade, setLowestGrade, setStdDevGrade) => {
    const allGrades = [...grades.Pass, ...grades.Fail];
    if (!allGrades || allGrades.length === 0) return;

    const total = allGrades.reduce((sum, grade) => sum + Number(grade), 0);
    const avg = total / allGrades.length;
    const sortedGrades = [...allGrades].sort((a, b) => a - b);
    const mid = Math.floor(sortedGrades.length / 2);
    const median = sortedGrades.length % 2 !== 0 ? Number(sortedGrades[mid]) : (Number(sortedGrades[mid - 1]) + Number(sortedGrades[mid])) / 2;
    const highest = Math.max(...allGrades);
    const lowest = Math.min(...allGrades);

    const variance = allGrades.reduce((sum, grade) => sum + Math.pow(Number(grade) - avg, 2), 0) / allGrades.length;
    const stdDev = Math.sqrt(variance);
    
    setAverageGrade(avg.toFixed(2));
    setMedianGrade(median.toFixed(2));
    setHighestGrade(highest.toFixed(2));
    setLowestGrade(lowest.toFixed(2));
    setStdDevGrade(stdDev.toFixed(2));
};

export const interpolateColor = (startColor, endColor, factor) => {
    const result = startColor.slice(1).match(/.{2}/g).map((hex, i) => {
        return Math.round(parseInt(hex, 16) + factor * (parseInt(endColor.slice(1).match(/.{2}/g)[i], 16) - parseInt(hex, 16)));
    });
    return `#${result.map(val => val.toString(16).padStart(2, '0')).join('')}`;
};


export const processData = (rawData, detailedPartialMarks) => {
    if (!rawData || Object.keys(rawData).length === 0) {
        return { labels: [], datasets: [], totalStudents: 0 };
    }

    const labels = Object.keys(rawData);
    const totalStudents = labels.reduce((max, label) => {
        const total = Object.values(rawData[label]).reduce((sum, val) => sum + (typeof val === 'number' ? val : 0), 0);
        return Math.max(max, total);
    }, 0);

    const datasets = [
        {
            label: '# Not attempted',
            data: labels.map(label => rawData[label]['# Not attempted'] || 0),
            backgroundColor: '#871751'
        },
        {
            label: '# Not checked',
            data: labels.map(label => rawData[label]['# Not checked'] || 0),
            backgroundColor: '#5D6CC9'
        },
        {
            label: '# Fully incorrect',
            data: labels.map(label => rawData[label]['# Fully incorrect'] || 0),
            backgroundColor: '#FD3C08'
        },
        {
            label: '# Partially correct',
            data: labels.map(label => rawData[label]['# Partially correct'] || 0),
            backgroundColor: '#FFB715'
        },
        {
            label: '# Fully correct',
            data: labels.map(label => rawData[label]['# Fully correct'] || 0),
            backgroundColor: '#BAEFB9'
        }
    ];

    if (detailedPartialMarks) {
        const partialDatasets = [];
        labels.forEach((key, index) => {
            const partialMarks = rawData[key]['Partial marks'] || {};
            Object.entries(partialMarks).forEach(([mark, count]) => {
                const existingDataset = partialDatasets.find(ds => ds.mark === Number(mark));
                if (existingDataset) {
                    existingDataset.data[index] = count;
                } else {
                    partialDatasets.push({
                        label: `Partial mark ${mark}`,
                        data: labels.map(() => 0),
                        backgroundColor: interpolateColor('#FD3C08', '#BAEFB9', Number(mark)),
                        mark: Number(mark)
                    });
                    partialDatasets[partialDatasets.length - 1].data[index] = count;
                }
            });
        });
        
        // Remove the general "# Partially correct" dataset
        datasets.splice(3, 1);
        // Insert the detailed partial marks datasets
        datasets.splice(3, 0, ...partialDatasets);
    }

    return { labels, datasets, totalStudents };
};

export const processGrades = (grades) => {
    console.log('Processing grades:', JSON.stringify(grades, null, 2));
    
    // Define grade ranges with special handling for 5-5.74 and 5.75-6
    const gradeRanges = [
        { min: 0, max: 1, label: '0 - 1' },
        { min: 1, max: 2, label: '1 - 2' },
        { min: 2, max: 3, label: '2 - 3' },
        { min: 3, max: 4, label: '3 - 4' },
        { min: 4, max: 5, label: '4 - 5' },
        { min: 5, max: 5.75, label: '5 - 5.74' },
        { min: 5.75, max: 6, label: '5.75 - 6' },
        { min: 6, max: 7, label: '6 - 7' },
        { min: 7, max: 8, label: '7 - 8' },
        { min: 8, max: 9, label: '8 - 9' },
        { min: 9, max: 10, label: '9 - 10' }
    ];

    // Initialize counts for each range
    const counts = gradeRanges.map(() => ({ pass: 0, fail: 0 }));

    // Process passing grades
    grades.Pass.forEach(grade => {
        const rangeIndex = gradeRanges.findIndex(range => 
            (grade >= range.min && grade < range.max) || 
            (grade === range.max && range.max === 10)
        );
        if (rangeIndex !== -1) {
            counts[rangeIndex].pass++;
        }
    });

    // Process failing grades
    grades.Fail.forEach(grade => {
        const rangeIndex = gradeRanges.findIndex(range => 
            grade >= range.min && grade < range.max || 
            (grade === range.max && range.max === 10)
        );
        if (rangeIndex !== -1) {
            counts[rangeIndex].fail++;
        }
    });

    return {
        labels: gradeRanges.map(range => range.label),
        datasets: [
            {
                label: 'Pass',
                data: counts.map(count => count.pass),
                backgroundColor: '#5D6CC9'
            },
            {
                label: 'Fail',
                data: counts.map(count => count.fail),
                backgroundColor: '#871751'
            }
        ]
    };
};