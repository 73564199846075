import React from 'react';

// This page is unfinished, as the integration with the SSO system hasn't been implemented yet
const Profile = () => {
  return (
    <div>
      <h1>Home Page</h1>
    </div>
  );
};

export default Profile;
