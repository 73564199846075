import React, { useEffect, useState } from 'react';
import { fetchCourses } from '../courses';
import styles from '../css/Selector.module.css';

const CourseSelector = ({ onSelectCourse }) => {
    const [courses, setCourses] = useState([]);
    const [selectedYear, setSelectedYear] = useState('all');
    const [availableYears, setAvailableYears] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);

    useEffect(() => {
        const loadCourses = async () => {
            try {
                const data = await fetchCourses();
                setCourses(data);
                
                // Extract unique years from courses
                const years = [...new Set(data.map(course => course.year))].sort((a, b) => b - a);
                setAvailableYears(years);
            } catch (error) {
                console.error('Error loading courses:', error);
            }
        };
        loadCourses();
    }, []);

    useEffect(() => {
        if (selectedYear === 'all') {
            setFilteredCourses(courses);
        } else {
            const filtered = courses.filter(course => course.year === parseInt(selectedYear));
            setFilteredCourses(filtered);
        }
    }, [selectedYear, courses]);

    const formatCourseName = (course) => {
        let formattedCode = '';
        if (course.course_code) {
            formattedCode = course.course_code.replace(/[\[\]]/g, '').trim();
            formattedCode = `${formattedCode} `;
        }
        return `${formattedCode}${course.name}`;
    };

    const formatAcademicYear = (year) => {
        if (!year) return 'Unknown';
        return `${year}/${year + 1}`;
    };

    return (
        <div className={styles.selectorContainer}>
            <div className={styles.yearFilter}>
                <select 
                    className={styles.yearSelect}
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                >
                    <option value="all">All Years</option>
                    {availableYears.map(year => (
                        <option key={year} value={year}>
                            {formatAcademicYear(year)}
                        </option>
                    ))}
                </select>
            </div>
            <select 
                className={styles.courseSelect} 
                onChange={e => onSelectCourse(e.target.value)}
            >
                <option value="">Select a course</option>
                {filteredCourses.map(course => (
                    <option key={course.id} value={course.id}>
                        {formatCourseName(course)} ({formatAcademicYear(course.year)})
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CourseSelector;